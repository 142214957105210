<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px">
                <el-row v-show="!this.disableGrid">
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：{{getTitle}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="录单日期:">
                                    <el-date-picker
                                            style=" width: 140px"
                                            v-model="formData.cash_date"
                                            :clearable="false"
                                            @blur="cashDateBlur"
                                            :disabled="formData.is_cancel"
                                            @change="isDataChanage = true"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark"
                                            :content="`单号唯一ID:${formData.id ? formData.id : '自动生成'}`" placement="top" :open-delay = "750">
                                    <el-form-item label="单据编号:">
                                        <el-input v-model="formData.cash_type_id" placeholder="可自动生成"
                                                  :disabled="formData.is_cancel"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="记录员:">
                                    <el-input ref="registrar" v-model="formData.registrar" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('create_date')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="创建时间:">
                                    <el-input ref="create_date" v-model="formData.create_date"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('customer')"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <template v-if="(isShowSKD || isShowQTSRD)">
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="bottom-end" :open-delay = "750">
                                        <div slot="content">
                                            {{formData.company_name}}
                                        </div>
                                        <el-form-item :label="'付款单位'">
                                            <el-select ref="company_id" v-model="formData.company_name"
                                                       style="width: 100%"
                                                       :disabled="formData.is_cancel"
                                                       default-first-option remote :remote-method="$_searchCustomerList"
                                                       clearable placeholder="可搜索查询"
                                                       filterable size="mini"
                                                       @change="isDataChanage = true,companyNameChangeEvent($event,'customer_name')"
                                                       @keyup.native.enter="$_blurNext('company_id'),$_focusNext('handled_by')">
                                                <el-option
                                                        v-for="item in this.$store.getters.getCustomerList"
                                                        :key="item.id"
                                                        :label="item.customer_name"
                                                        :value="item">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                            </template>


                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="经手人:">
                                    <el-select ref="handled_by" v-model="formData.handled_by" clearable
                                               placeholder="可搜索查询"
                                               size="mini"
                                               default-first-option
                                               :disabled="formData.is_cancel"
                                               :filter-method="$_handledByListFilter"
                                               @change="isDataChanage = true"
                                               @keyup.native.enter="$_blurNext('handled_by'),$_focusNext('remark_cash')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getHandledByList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="单据备注:">
                                    <el-input ref="remark_cash" v-model="formData.remark_cash" size="mini"
                                              :disabled="formData.is_cancel"
                                              @change="isDataChanage = true"
                                              @keyup.native.enter="$_blurNext('remark_cash'),$_focusNext('additional_remark')"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="附加说明:">
                                    <el-input ref="additional_remark" v-model="formData.additional_remark"
                                              :disabled="formData.is_cancel"
                                              @change="isDataChanage = true"
                                              @keyup.native.enter="$_blurNext('additional_remark')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="修改人:">
                                    <el-input ref="last_name_cash" v-model="formData.last_name_cash"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('bill_type')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="修改时间:">
                                    <el-input ref="last_date_cash" v-model="formData.last_date_cash"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('last_name_cash')"></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>

                <el-row v-show="!this.disableGrid">
                    <el-col :lg="24">
                        <el-row>

                            <vxe-grid
                                    border
                                    resizable
                                    show-overflow
                                    keep-source
                                    ref="CashDataxGrid"
                                    size="mini"
                                    :height="170"
                                    highlight-current-row
                                    :print-config="{}"
                                    @keydown="$_onGridKeydown"
                                    @cell-click="cellClickEventMain"
                                    :cell-class-name="cellClassName"
                                    @cell-dblclick="cellShowTableEvent"
                                    :data="tableDataMain"
                                    :columns="tableColumn"
                                    :mouse-config="{selected: true}"
                                    :edit-config="{showIcon:true,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                                    :keyboard-config="{enterToTab:false,isArrow: false, isDel: false, isEnter: false, isTab: true,isEdit:!formData.is_cancel,enabled:!formData.is_cancel}">
                                <!-- 摘要查询 提示 -->
                                <template #summary_case_data_edit="{row}">
                                    <vxe-pulldown ref="xDownSummary" transfer>
                                        <template #default>
                                            <vxe-input v-model="row.summary_cash_data" clearable
                                                       suffix-icon="fa fa-search"
                                                       :disabled="formData.is_cancel"
                                                       @keyup="keyUpDropDownEvent(dropDownTableDataSummary,$event,'dropDownGridSummary','xDownSummary','xDownSummaryIsShow')"
                                                       @focus="$_searchSummaryList(row.summary_cash_data),$refs['xDownSummary'].showPanel(),xDownSummaryIsShow=true"
                                                       @blur="xDownSummaryIsShow=false"
                                                       @input="dropDownTablePage.currentPage = 1"
                                                       @change="$_searchSummaryList(row.summary_cash_data),dropDownTablePageSummary.currentPage = 1"></vxe-input>
                                        </template>
                                        <template #dropdown>
                                            <div class="my-dropdown4">
                                                <vxe-grid
                                                        ref="dropDownGridSummary"
                                                        border
                                                        resizable
                                                        show-overflow
                                                        keep-source
                                                        auto-resize
                                                        size="mini"
                                                        height="250"
                                                        :radio-config="{highlight: true,trigger:'row'}"
                                                        :loading="loading"
                                                        :pager-config="dropDownTablePageSummary"
                                                        :data="dropDownTableDataSummary"
                                                        :columns="dropDownTableColumnSummary"
                                                        @radio-change="radioSummaryChangeEvent"
                                                        @page-change="pageChangeSummaryEvent">
                                                </vxe-grid>
                                            </div>
                                        </template>
                                    </vxe-pulldown>
                                </template>

                                <!-- 科目编码 cashData 查询 提示 -->
                                <!--重要：如果下拉框上下选择，用到getActiveRecord 默认会去掉 隐藏的行，所以隐藏行需要放到最后面-->
                                <template #subject_id_edit="{row}">
                                    <vxe-pulldown ref="xDownSubjectId" transfer>
                                        <template #default>
                                            <vxe-input v-model="row.subject_id_cash_data" clearable
                                                       suffix-icon="fa fa-search"
                                                       :disabled="formData.is_cancel"
                                                       @clear="clearSujectEvent(row)"
                                                       @keyup="keyUpDropDownEvent(dropDownTableData,$event,'dropDownGrid','CashDataxGrid','xDownSubjectId','xDownSubjectIdIsShow')"
                                                       @focus="$_searchSubjectList(row.subject_id_cash_data),$refs.xDownSubjectId.showPanel(),xDownSubjectIdIsShow=true"
                                                       @blur="xDownSubjectIdIsShow=false,$_cheakHasSubject(row)"
                                                       @input="dropDownTablePage.currentPage = 1"
                                                       @change="$_searchSubjectList(row.subject_id_cash_data)"></vxe-input>
                                        </template>
                                        <template #dropdown>
                                            <div class="my-dropdown4">
                                                <vxe-grid
                                                        ref="dropDownGrid"
                                                        border
                                                        resizable
                                                        show-overflow
                                                        keep-source
                                                        auto-resize
                                                        size="mini"
                                                        height="250"
                                                        :radio-config="{highlight: true,trigger:'row'}"
                                                        :loading="loading"
                                                        :pager-config="dropDownTablePage"
                                                        :data="dropDownTableData"
                                                        :columns="dropDownTableColumn"
                                                        @cell-click="radioChangeEvent($event,true,'dropDownGrid','CashDataxGrid','xDownSubjectId','xDownSubjectIdIsShow')"
                                                        @radio-change="radioChangeEvent($event,true,'dropDownGrid','CashDataxGrid','xDownSubjectId','xDownSubjectIdIsShow')"
                                                        @page-change="pageChangeEvent">
                                                </vxe-grid>
                                            </div>
                                        </template>
                                    </vxe-pulldown>
                                </template>
                                <!-- 金额 -->
                                <template #money_case_data_edit="{row}">
                                    <vxe-input
                                            @change="getPaidUp(),getTotalAmount(),getAllocatedAmount(),getDispensAbility()"
                                            v-model="row.money_cash_data" type="float" clearable
                                            :disabled="formData.is_cancel"
                                            :controls="false"></vxe-input>
                                </template>
                                <!-- 冲账金额 -->
                                <template #balance_money_edit="{row}">
                                    <vxe-input
                                            @change="getTotalBalanceMoney(),getPaidUp(),getTotalAmount(),getAllocatedAmount(),getDispensAbility()"
                                            :disabled="formData.is_cancel"
                                            v-model="row.balance_money" type="float" clearable
                                            :controls="false"></vxe-input>
                                </template>
                                <!-- 出票单位 -->
                                <template #draw_company_edit="{row}">
                                    <el-select ref="draw_company" v-model="row.draw_company"
                                               @focus="$_drawCompanySelectorFocusEvent({row})"
                                               default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                               clearable filterable remote
                                               :disabled="formData.is_cancel"
                                               :remote-method="$_searchCustomerAndSupplier">
                                        <el-option v-for="(item,key) in $store.getters.getCustomerAndSupplierList" :key="key"
                                                   :label="item.draw_company" :value="item.draw_company">
                                            <span style="margin-right:8px;">{{item.draw_company}}</span>
                                        </el-option>
                                    </el-select>
                                </template>

                            </vxe-grid>
                        </el-row>

                        <el-row>
                            <template>

                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="bottom-end" :open-delay = "750">
                                        <div slot="content">
                                            {{formData.company_name}}
                                        </div>
                                        <el-form-item :label="'应收账款'">
                                            <el-select ref="company_id" v-model="formData.company_name"
                                                       style="width: 100%"
                                                       :disabled="formData.is_cancel"
                                                       default-first-option remote :remote-method="$_searchSupplierList"
                                                       clearable placeholder="可搜索查询"
                                                       filterable size="mini"
                                                       @change="isDataChanage = true,companyNameChangeEvent($event,'supplier_name')"
                                                       @keyup.native.enter="$_blurNext('company_id'),$_focusNext('handled_by')">
                                                <el-option
                                                        v-for="item in $store.getters.getSupplierList"
                                                        :key="item.id"
                                                        :label="item.supplier_name"
                                                        :value="item">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                            </template>

                        </el-row>
                        <template v-if="this.formData.cash_type != 'SDJ' && this.formData.cash_type != 'YFK'">

                            <vxe-grid
                                    border
                                    resizable
                                    show-overflow
                                    keep-source
                                    :show-footer="false"
                                    ref="xGridFooter"
                                    :footer-method="footerMethod"
                                    size="mini"
                                    height="160"
                                    highlight-hover-row
                                    :print-config="{}"
                                    :data="footerData"
                                    :columns="footerTableColumn"
                                    :mouse-config="{selected: true}"
                                    @keydown="$_onGridKeydown"
                                    :edit-config="{showIcon:true,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                                    @cell-click="cellClickEventFooter"
                                    @cell-dblclick="cellClickEvent"
                                    :keyboard-config="{enterToTab:false,isArrow: false, isDel: false, isEnter: false, isTab: true,isEdit:!formData.is_cancel,enabled:!formData.is_cancel}">
                                <!-- 出票单位 -->

                                <template #draw_company_edit="{row}">
                                    <el-select ref="draw_company" v-model="row.draw_company"
                                               @focus="$_drawCompanySelectorFocusEvent({row})"
                                               default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                               clearable filterable remote
                                               :remote-method="$_searchCustomerAndSupplier">
                                        <el-option v-for="(item,key) in $store.getters.getCustomerAndSupplierList" :key="key"
                                                   :label="item.draw_company" :value="item.draw_company">
                                            <span style="margin-right:8px;">{{item.draw_company}}</span>
                                        </el-option>
                                    </el-select>
                                </template>

                                <!-- 结算金额 -->
                                <template #amount_edit="{ row}">
                                    <vxe-input
                                            @change="getPaidUp(),getTotalAmount(),getAllocatedAmount(),getDispensAbility(),getHangMoney(),setBalance(row)"
                                            v-model="row.amount" type="float" clearable
                                            :disabled="formData.is_cancel"
                                            :controls="false"></vxe-input>
                                </template>
                                <!-- 科目查询 footer 提示 -->
                                <!--重要：如果下拉框上下选择，用到getActiveRecord 默认会去掉 隐藏的行，所以隐藏行需要放到最后面-->
                                <template #subject_id_footer_edit="{ row}">
                                    <vxe-pulldown ref="xDownSubjectIdFooter" transfer>
                                        <template #default>
                                            <vxe-input v-model="row.subject_id_cash_data" clearable
                                                       suffix-icon="fa fa-search"
                                                       @keyup="keyUpDropDownEvent(dropDownTableData,$event,'dropDownGridFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                       @focus="$_searchSubjectList(row.subject_id_cash_data),$refs.xDownSubjectIdFooter.showPanel(),xDownSubjectIdIsShowFooter=true"
                                                       @blur="xDownSubjectIdIsShowFooter=false,$_cheakHasSubject(row)"
                                                       :disabled="formData.is_cancel"
                                                       @change="$_searchSubjectList(row.subject_id_cash_data)"></vxe-input>
                                        </template>
                                        <template #dropdown>
                                            <div class="my-dropdown4">
                                                <vxe-grid
                                                        ref="dropDownGridFooter"
                                                        border
                                                        resizable
                                                        show-overflow
                                                        keep-source
                                                        auto-resize
                                                        size="mini"
                                                        height="250"
                                                        :radio-config="{highlight: true,trigger:'row'}"
                                                        :loading="loading"
                                                        :pager-config="dropDownTablePage"
                                                        :data="dropDownTableData"
                                                        :columns="dropDownTableColumn"
                                                        @cell-click="radioChangeEvent($event,true,'xDownSubjectIdFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                        @radio-change="radioChangeEvent($event,true,'xDownSubjectIdFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                        @page-change="pageChangeEvent">
                                                </vxe-grid>
                                            </div>
                                        </template>
                                    </vxe-pulldown>
                                </template>
                            </vxe-grid>
                        </template>

                        <el-row>
                            <template>
                                <el-col :lg="3">
                                    <el-radio-group v-model="formData.cash_type" :disabled="formData.is_cancel"
                                                    style=" margin-top: 14px; ">
                                        <el-radio @click.native.prevent="radioEvent('SKD'),isDataChanage = true"
                                                  label="SKD">应收
                                        </el-radio>
                                        <!--<el-radio label="ZPRZ">支票入账</el-radio>-->
                                        <el-radio @click.native.prevent="radioEvent('ZJRZ'),isDataChanage = true"
                                                  label="ZJRZ">应付
                                        </el-radio>

                                    </el-radio-group>

                                </el-col>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="bottom-end" :open-delay = "750">
                                        <div slot="content">
                                            {{formData.company_name}}
                                        </div>
                                        <el-form-item :label="'公司单位'">
                                            <el-select ref="company_id" v-model="formData.companyName2"
                                                       style="width: 100%"
                                                       :disabled="formData.is_cancel"
                                                       default-first-option remote :remote-method="$_searchSupplierList"
                                                       clearable placeholder="可搜索查询"
                                                       filterable size="mini"
                                                       @change="isDataChanage = true,companyNameChangeEvent($event,'supplier_name')"
                                                       @keyup.native.enter="$_blurNext('company_id'),$_focusNext('handled_by')">
                                                <el-option
                                                        v-for="item in $store.getters.getSupplierList"
                                                        :key="item.id"
                                                        :label="item.supplier_name"
                                                        :value="item">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                            </template>
                        </el-row>
                        <template v-if="this.formData.cash_type != 'SDJ' && this.formData.cash_type != 'YFK'">

                            <vxe-grid
                                    border
                                    resizable
                                    show-overflow
                                    keep-source
                                    :show-footer="false"
                                    ref="xGridFooter"
                                    :footer-method="footerMethod"
                                    size="mini"
                                    height="180"
                                    highlight-hover-row
                                    :print-config="{}"
                                    :data="footerData"
                                    :columns="footerTableColumn"
                                    :mouse-config="{selected: true}"
                                    @keydown="$_onGridKeydown"
                                    :edit-config="{showIcon:true,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                                    @cell-click="cellClickEventFooter"
                                    @cell-dblclick="cellClickEvent"
                                    :keyboard-config="{enterToTab:false,isArrow: false, isDel: false, isEnter: false, isTab: true,isEdit:!formData.is_cancel,enabled:!formData.is_cancel}">
                                <!-- 出票单位 -->

                                <template #draw_company_edit="{ row}">
                                    <el-select ref="draw_company" v-model="row.draw_company"
                                               @focus="$_drawCompanySelectorFocusEvent({row})"
                                               default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                               clearable filterable remote
                                               :remote-method="$_searchCustomerAndSupplier">
                                        <el-option v-for="(item,key) in $store.getters.getCustomerAndSupplierList" :key="key"
                                                   :label="item.draw_company" :value="item.draw_company">
                                            <span style="margin-right:8px;">{{ item.draw_company}}</span>
                                        </el-option>
                                    </el-select>
                                </template>

                                <!-- 结算金额 -->
                                <template #amount_edit="{ row}">
                                    <vxe-input
                                            @change="getPaidUp(),getTotalAmount(),getAllocatedAmount(),getDispensAbility(),getHangMoney(),setBalance(row)"
                                            v-model="row.amount" type="float" clearable
                                            :disabled="formData.is_cancel"
                                            :controls="false"></vxe-input>
                                </template>
                                <!-- 科目查询 footer 提示 -->
                                <!--重要：如果下拉框上下选择，用到getActiveRecord 默认会去掉 隐藏的行，所以隐藏行需要放到最后面-->
                                <template #subject_id_footer_edit="{ row}">
                                    <vxe-pulldown ref="xDownSubjectIdFooter" transfer>
                                        <template #default>
                                            <vxe-input v-model="row.subject_id_cash_data" clearable
                                                       suffix-icon="fa fa-search"
                                                       @keyup="keyUpDropDownEvent(dropDownTableData,$event,'dropDownGridFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                       @focus="$_searchSubjectList(row.subject_id_cash_data),$refs.xDownSubjectIdFooter.showPanel(),xDownSubjectIdIsShowFooter=true"
                                                       @blur="xDownSubjectIdIsShowFooter=false,$_cheakHasSubject(row)"
                                                       :disabled="formData.is_cancel"
                                                       @change="$_searchSubjectList(row.subject_id_cash_data)"></vxe-input>
                                        </template>
                                        <template #dropdown>
                                            <div class="my-dropdown4">
                                                <vxe-grid
                                                        ref="dropDownGridFooter"
                                                        border
                                                        resizable
                                                        show-overflow
                                                        keep-source
                                                        auto-resize
                                                        size="mini"
                                                        height="250"
                                                        :radio-config="{highlight: true,trigger:'row'}"
                                                        :loading="loading"
                                                        :pager-config="dropDownTablePage"
                                                        :data="dropDownTableData"
                                                        :columns="dropDownTableColumn"
                                                        @cell-click="radioChangeEvent($event,true,'xDownSubjectIdFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                        @radio-change="radioChangeEvent($event,true,'xDownSubjectIdFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                        @page-change="pageChangeEvent">
                                                </vxe-grid>
                                            </div>
                                        </template>
                                    </vxe-pulldown>
                                </template>
                            </vxe-grid>
                        </template>

                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="合计:">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.total_amount?formData.total_amount:0, { digits: 2})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="实收:">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.paid_up ?formData.paid_up :0, { digits: 2})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="可分配金额:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="formData.dispens_ability ?formData.dispens_ability :0"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="已分配金额:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.allocated_amount?formData.allocated_amount:0, { digits: 2})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="挂数金额:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.hang_money ?formData.hang_money :0, { digits: 2})"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="合计冲账:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.total_balance_money ?formData.total_balance_money :0, { digits: 2})"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="6" id="openCashTypeId" style=" padding-top: 7px; padding-left: 22px; ">
                        <el-input size="mini" type="number" placeholder="请输入" v-model="openCashTypeId"
                                  class="input-with-select">
                            <template slot="prepend">
                                月份：
                                <el-date-picker
                                        style="width:100px"
                                        size="mini"
                                        :clearable="false"
                                        v-model="formData.year_month"
                                        type="month"
                                        value-format="yyyy-MM-dd"
                                        @change="changeYearMonthEvent()"
                                        placeholder="选择月">
                                </el-date-picker>
                                跳转单据编号：
                            </template>
                            <el-button @click="openCashTypeIdEvent()" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                    <!--<el-col :lg="4" :sm="12" :md="8">
                        <el-form-item label="超收转订金:" label-width="100px">
                            <vxe-input size="mini" @change="getDispensAbility()" v-model="formData.excessToAdvance" min="0" :controls="false" type="float" clearable></vxe-input>
                        </el-form-item>
                    </el-col>-->
                </el-row>
                <el-row>
                    <el-col :lg="5" :sm="12" :md="8">
                        <!--大写实收金额-->
                        <a style="float: right;margin-right: 5mm">合计：{{$_digitUppercase(formData.total_amount?formData.total_amount:0)}}</a>
                    </el-col>
                    <!--<el-col :lg="2" style="margin-left: 20px">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini">收款情况
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini">检查勾单
                        </el-button>
                    </el-col>-->

                    <!--<el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini">立即打印
                        </el-button>
                    </el-col>-->
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " @click="automatic" type="primary"
                                   size="mini">自动分配
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary"
                                   @click="$router.push({ path: '/receipt', query: { type:formData.type}})"
                                   size="mini">新建
                        </el-button>
                    </el-col>

                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " :disabled="formData.is_cancel"
                                   type="danger" plain @click="onSubmit"
                                   size="mini">保存
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini" @click="$router.back()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">返回
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini" @click="preBill()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">上一张
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini" @click="nextBill()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">下一张
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini"
                                   :disabled="formData.is_cancel || this.isBlankVue(formData.id)" @click="setIsCancel()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">{{formData.is_cancel ? '已作废': '作废'}}
                        </el-button>
                    </el-col>

                    <!-- <el-col :lg="4" :sm="12" :md="8">
                         <el-form-item label="超收转订金(单号):" label-width="130px">
                             <el-input size="mini" :readonly="true" placeholder="自动生成" :value="formData.idSDJ"></el-input>
                         </el-form-item>
                     </el-col>-->
                    <vxe-modal v-model="showAssistModel" :lock-view="false" :mask="false" resize
                               margin-size="-500" width="400" title="辅助核算">
                        <template #default>
                            <el-form>
                                <el-row>
                                    <el-col :lg="18" :md="18">
                                        <el-form-item label="往来单位:">
                                            <el-select v-model="tempCompanyAssistModel" value-key="company_name"
                                                       default-first-option remote
                                                       :remote-method="$_searchAllCompany" clearable filterable
                                                       size="mini"
                                                       placeholder="可搜索查询">
                                                <el-option
                                                        v-for="item in $store.getters.getAllCompanyList"
                                                        :key="item.company_uuid"
                                                        :label="item.company_name"
                                                        :value="item">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :lg="4" :sm="4" :md="4">
                                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " @click="$_okSetAssist"
                                                   type="primary" size="mini">确认
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </template>
                    </vxe-modal>

                    <vxe-modal v-model="showModel.isShow" :lock-view="false" :mask="false" show-zoom resize
                               margin-size="-500" width="1200" title="销售、进仓(单据)">
                        <template #default>
                            <el-row>
                                <el-col :lg="5" :sm="12" :md="8">
                                    <el-date-picker
                                            style=" margin-bottom: 8px;width: 220px"
                                            v-model="pickerDate"
                                            type="daterange"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            align="left">
                                    </el-date-picker>
                                </el-col>
                                <el-col :lg="2" :sm="12" :md="8">
                                    <el-button id="createBill" type="primary" @click="searchWarehouseBillByFees" round
                                               size="mini">搜索查询
                                    </el-button>
                                </el-col>
                            </el-row>
                            <vxe-grid
                                    border
                                    ref="modalGrid"
                                    show-overflow
                                    resizable
                                    height="450"
                                    size="mini"
                                    highlight-current-row
                                    @cell-dblclick="cellClickEventModal"
                                    :cell-class-name="cellClassNameModal"
                                    :data="tableDataModel"
                                    :mouse-config="{selected: true}"
                                    :pager-config="tableDataModelPage"
                                    :columns="tableColumnModel">
                            </vxe-grid>
                        </template>
                    </vxe-modal>
                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                tempCompanyAssistModel: '',//弹窗辅助项目
                tempGrid: '',
                tempRow: {},//用于判断是否存在辅助项目，存在则缓存起来当前行，当选中点击确定，直接赋值
                showAssistModel: false,
                rowMain: {},
                showModel: {
                    isShow: false,
               },
                tableDataModel: [],
                tableDataModelPage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [500, 1000, 2000, 5000]
               },
                filterData: {},
                sortData: {},
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 90), new Date()],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(), new Date()]);
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [date, date]);
                       }
                   }, {
                        text: '最近周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [date, new Date()]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }]
               },
                tableColumnModel: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'total_amount', width: 130, title: '应收、应付(金额)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'practical_total_price', width: 170, title: '(实收)应收、应付(金额)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'payend', width: 80, title: '已结金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'kick_back_total', width: 80, title: '佣金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'kick_back_total_balance', width: 89, title: '佣金(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'pounds_fees', width: 80, title: '磅费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'pounds_fees_balance', width: 89, title: '磅费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'wind_cut_fees', width: 80, title: '风割费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'wind_cut_fees_balance', width: 115, title: '风割费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'loading_car_fees', width: 80, title: '装车费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'loading_car_fees_balance', width: 115, title: '装车费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'transfer_fees', width: 80, title: '中转费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'transfer_fees_balance', width: 115, title: '中转费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'freight_fees', width: 80, title: '运费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'freight_fees_balance', width: 89, title: '运费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'taxes_fees', width: 80, title: '税金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'taxes_fees_balance', width: 89, title: '税金(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },


                ],
                openCashTypeId: '',//用于跳转的单号
                disableGrid: false,
                xDownSummaryIsShow: false,
                xDownSubjectIdIsShow: false,
                xDownSubjectIdIsShowFooter: false,
                loading: false,
                dropDownTableColumn: [
                    {type: 'radio', title: '选择', width: 40},
                    {field:'id_subject', title: '科目编码', width: 90},
                    {field:'subject_name', title: '科目名称', width: 210},
                    {field:'fater_name', title: '上级科目', width: 210},
                ],
                dropDownTableData: [],
                dropDownTablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [5, 10, 20, 50, 100, 200, 500]
               },
                dropDownTableColumnSummary: [
                    {type: 'radio', title: '选择', width: 40},
                    {field:'id', title: '编码', width: 100},
                    {field:'summary_name', title: '常用摘要', width: 220},
                ],
                //摘要
                dropDownTableDataSummary: [],
                dropDownTablePageSummary: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [5, 10, 20, 50, 100, 200, 500]
               },

                formData: {
                    id: '',//收款单 ID
                    year_month : new Date(),//年月
                    year: '',//年份
                    month: '',//月份
                    type: 'SKD',//收款单类型 SKD、FKD
                    total_amount: 0,//合计
                    paid_up : 0,//实收金额
                    total_balance_money : 0,//合计冲账金额 凭证折扣
                    hang_money : 0,
                    balance: 0,
                    dispens_ability : '',//可分配金额
                    allocated_amount: '',//已分配金额
                    cash_type_id : '',//收款单对应不同类型的 ID
                    cash_type : 'SKD',//收款单类型 SKD、ZPRZ、ZJRZ、SDD
                    cash_date: new Date(),//录单日期
                    handled_by: '',//经手人
                    company_name : '',//付款单位
                    company_id : '',//付款单位Id
                    remark_cash: '',//备注
                    registrar: '',//记录人
                    additional_remark: '',//附加说明
                    last_name_cash: '',//最后修改人
                    last_date_cash: '',//最后修改日期
                    create_date: '',//创建日期
                    is_cancel: false,//作废
                    is_delete : false,//删除
                    //excessToAdvance: '',//超收转订金
                    //idSDJ: '',//超收转订金(单号)
               },
                start_date: '',
                tableColumn: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'summary_cash_data',
                        width: 122,
                        title: '摘要',
                        slots: {edit: 'summary_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_id_cash_data',
                        width: 122,
                        title: '科目编码',
                        slots: {edit: 'subject_id_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_name_cash_data',
                        width: 222,
                        className: 'unEditor',
                        title: '科目名称'
                   }, {
                        field:'company_name_assist',
                        width: 100,
                        className: 'unEditor',
                        title: '辅助核算'
                   },
                    {
                        field:'money_cash_data',
                        width: 122,
                        title: '金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        slots: {edit: 'money_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'check_number',
                        width: 120,
                        title: '支票号码',
                        editRender: {name: 'ElInput', props: {controls: false}}
                   },
                    {
                        field:'check_date',
                        width: 120,
                        title: '支票日期',
                        editRender: {name: '$input', props: {type: 'date'}},
                   },
                    {
                        field:'draw_company',
                        width: 220,
                        title: '出票单位',
                        slots: {edit: 'draw_company_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'balance_money',
                        width: 120,
                        title: '冲账金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        slots: {edit: 'balance_money_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   }, {field:'cash_data_remark', title: '备注', editRender: {name: 'input'}}
                ],
                tableColumnYBFYD: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'summary_cash_data',
                        width: 122,
                        title: '摘要',
                        slots: {edit: 'summary_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_id_cash_data',
                        width: 122,
                        title: '科目编码',
                        slots: {edit: 'subject_id_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_name_cash_data',
                        width: 222,
                        className: 'unEditor',
                        title: '科目名称'
                   }, {
                        field:'company_name_assist',
                        width: 100,
                        className: 'unEditor',
                        title: '辅助核算'
                   },
                    {
                        field:'money_cash_data',
                        width: 122,
                        title: '金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        slots: {edit: 'money_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {field:'bill_id_kd_or_jc', width: 150, title: '销售、进仓（单号）'},
                    {field:'cash_data_remark', width: 150, title: '备注', editRender: {name: 'input'}},
                    {field:'company_name', width: 180, className: 'unEditor', title: '供应商、客户'},

                    {field:'sales_man', width: 70, className: 'unEditor', title: '业务员'},
                    {
                        field:'fees_name',
                        titleHelp: {message: '例如：过磅费：\n则会修改对应销售单已付过磅费的金额\n（跟入的科目没关系）'},
                        className: 'unEditor',
                        title: '销售、进仓（费用名称）'
                   },
                    {field:'company_uuid', visible: false, width: 150, className: 'unEditor', title: 'UUID'},
                ],
                tableDataMain: [],
                footerData: [],
                footerTableColumn: [],
                footerTableColumnYSCYS: [
                    {type: 'seq', title: '序号', width: 40},

                    {
                        field:'bill_id_kd_or_jc', width: 122, title: '单据编号', formatter: ({row}) => {
                            if (row.cash_type == 'SKD' || row.cash_type == 'FKD') {
                                return row.bill_id_kd_or_jc
                           } else if (row.cash_type == 'SDJ' || row.cash_type == 'YFK') {
                                return row.cash_type_id;
                           } else {
                                return '未知异常'
                           }
                       }
                   },

                    {
                        field:'cash_type', width: 122, title: '单据类型', formatter: ({cellValue}) => {
                            if (cellValue == 'SKD') {
                                return '销售单产生应收'
                           } else if (cellValue == 'SDJ') {
                                return '订金产生应收'
                           } else if (cellValue == 'FKD') {
                                return '外购单产生应付'
                           } else if (cellValue == 'YFK') {
                                return '预付款产生应付'
                           } else {
                                return '未知异常'
                           }
                       }
                   },
                    {field:'remark', width: 150, title: '订单备注'},
                    {
                        field:'cash_date', width: 120, title: '开单日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                   },
                    {
                        field:'registrar',
                        width: 70,
                        title: '记录员'
                   },
                    {
                        field:'sales_man',
                        width: 70,
                        title: '业务员'
                   },
                    {
                        field:'total_amount',
                        width: 120,
                        title: '金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       }
                   },
                    {
                        field:'balance',
                        width: 120,
                        title: '余额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }
                   },
                    {
                        field:'payend',
                        width: 120,
                        visible: true,
                        title: '已支付',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }
                   },

                    {
                        field:'amount',
                        width: 120,
                        title: '结算金额',
                        slots: {edit: 'amount_edit'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'isOk',
                        width: 70,
                        title: '确认',
                        formatter: ({row}) => {
                            if (row.balance == 0) {
                                return '✓'
                           } else {
                                return ''
                           }
                       },
                   }, {
                        field:'amount_other',
                        width: 120,
                        visible: false,
                        title: '其他单已结算金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                   },
                    {
                        field:'amount_this',
                        width: 120,
                        visible: false,
                        title: '本单已结算金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                   }, {field:'id', width: 55, title: 'id', visible: false},
                    {field:'cash_type_id', width: 55, title: 'cash_type_id', visible: false},
                ],
                //支票入账，暂时废弃
                footerTableColumnZPRZ: [
                    {type: 'seq', title: '序号', width: 40},
                    {field:'check_number', width: 150, title: '支票号码', editRender: {name: 'input'}},
                    {field:'check_type', width: 150, title: '类型', editRender: {name: 'input'}},
                    {
                        field:'draw_company', width: 150, title: '出票单位',
                        slots: {edit: 'draw_company_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'amount',
                        width: 120,
                        title: '支票金额',
                        slots: {edit: 'amount_edit'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                ],
                //资金入账、其他收入单
                footerTableColumnZJRZorQTSRDorYBFYD: [
                    {type: 'seq', title: '序号', width: 40},

                    {
                        field:'subject_id_cash_data', title: '科目编码',
                        slots: {edit: 'subject_id_footer_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}, width: 90
                   },
                    {field:'subject_name_cash_data', title: '科目名称', className: 'unEditor', width: 210},
                    {
                        field:'company_name_assist',
                        width: 100,
                        className: 'unEditor',
                        title: '辅助核算'
                   },
                    {field:'check_number', width: 150, title: '支票号码', editRender: {name: 'input'}},
                    {
                        field:'draw_company', width: 150, title: '出票单位',
                        slots: {edit: 'draw_company_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'amount',
                        width: 120,
                        title: '金额',
                        slots: {edit: 'amount_edit'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {field:'remark', width: 150, title: '备注', editRender: {name: 'input'}},
                    {field:'id', visible: false, width: 55, title: 'id'},
                ],
                footerTableColumnSDJ: [],
                footerTableColumnFKD: [
                    {type: 'seq', title: '序号', width: 40},
                    {field:'bill_id_kd', width: 122, title: '单据编号'},
                    {
                        field:'cash_type', width: 122, title: '单据类型'
                   },
                    {field:'remark_warehouse_bill', width: 150, title: '订单备注'},
                    {
                        field:'bill_date_kd', width: 120, title: '开单日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                   },
                    {
                        field:'registrar',
                        width: 70,
                        title: '记录员'
                   },
                    {
                        field:'sales_man',
                        width: 70,
                        title: '业务员'
                   },
                    {
                        field:'total_amount',
                        width: 120,
                        title: '金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       }
                   },
                    {
                        field:'balance',
                        width: 120,
                        title: '余额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }
                   },
                    {
                        field:'payend',
                        width: 120,
                        visible: true,
                        title: '已支付',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }
                   },

                    {
                        field:'amount',
                        width: 120,
                        title: '结算金额',
                        slots: {edit: 'amount_edit'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }, editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'isOk',
                        width: 120,
                        title: '确认',
                        formatter: ({row}) => {
                            if (row.balance == 0) {
                                return '✓'
                           } else {
                                return ''
                           }
                       },
                   }
                ],
                activeName: 'first',
                checked: false,
           }
       },
        methods: {

            //查询供应商 收款单位
            searchWarehouseBillByFees() {
                this.$axios({
                    method: 'post',
                    url: '/admin/receipt/searchWarehouseBillByFees',
                    data: {
                        currentPage: this.tableDataModelPage.currentPage,
                        pageSize: this.tableDataModelPage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.tableDataModel = response.data.page.list
                        this.tableDataModelPage.pageSize = response.data.page.pageSize
                        this.tableDataModelPage.total = response.data.page.totalRow
                        this.tableDataModelPage.currentPage = response.data.page.pageNumber
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            cellShowTableEvent({row, column}) {
                if (column.property == 'bill_id_kd_or_jc') {
                    //弹出一个table
                    console.log(row, column);
                    this.showModel.isShow = true;
                    this.rowMain = row;
                    this.searchWarehouseBillByFees();
               }

           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count ? this.$XEUtils.round(count, 2) : 0
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property == 'amount' || column.property == 'payend' || column.property == 'balance' || column.property == 'total_amount') {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            cellClassName({row, column}) {
                if (column.property === 'bill_id_kd_or_jc') {
                    if (!this.isBlankVue(row.bill_id_kd_or_jc)) {
                        return 'col-success'
                   } else {
                        return 'col-fail'
                   }
               }
           },
            cellClassNameModal({row, column}) {
                if (column.property === 'kick_back_total_balance') {
                    if (row.kick_back_total_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'pounds_fees_balance') {
                    if (row.pounds_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'wind_cut_fees_balance') {
                    if (row.wind_cut_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'loading_car_fees_balance') {
                    if (row.loading_car_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'transfer_fees_balance') {
                    if (row.transfer_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'freight_fees_balance') {
                    if (row.freight_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'taxes_fees_balance') {
                    if (row.taxes_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
           },
            //修改日期
            changeYearMonthEvent() {
                if (!this.isBlankVue(this.formData.year_month)) {

                    this.formData.year = new Date(this.formData.year_month).getFullYear();
                    this.formData.month = (new Date(this.formData.year_month).getMonth() + 1);
                    //console.log(this.formData.year_month, "year: ", this.formData.year, " month: ", this.formData.month);
               }
           },
            //跳转单据
            openCashTypeIdEvent() {
                if (this.openCashTypeId == '' || this.openCashTypeId == null) {
                    this.$XModal.alert({content: '跳转单号不能为空！', maskClosable: true})
               } else {
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : this.openCashTypeId,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               }
           },
            //作废
            setIsCancel() {
                if (this.formData.id != null && this.formData.id != '') {
                    if (this.$_isGridDataChange(this.$refs['CashDataxGrid']) || this.$_isGridDataChange(this.$refs['xGridFooter']) || this.isDataChanage) {
                        this.$message({
                            showClose: true,
                            message: '当前数据已被修改，请先保存，再作废！',
                            type: 'error'
                       });
                   } else {
                        this.$XModal.confirm("作废后将：不能修改，不能反作废。").then(type => {
                            //const $table = this.$refs.xTable
                            if (type === 'confirm') {
                                this.$axios({
                                    method: 'post',
                                    url: '/admin/receipt/setIsCancel',
                                    data: {
                                        cash_id: this.formData.id,
                                        footerData: this.footerData
                                   }
                               }).then((response) => {          //这里使用了ES6的语法
                                    //console.log(response)       //请求成功返回的数据
                                    if (response.status === 200 && response.data.state == 'ok') {
                                        this.$message({
                                            showClose: true,
                                            message: '保存成功',
                                            type: 'success'
                                       });
                                        this.$router.push({
                                            path: '/receipt',
                                            query: {
                                                id: this.formData.id,
                                           }
                                       })
                                   } else {
                                        this.$message({
                                            showClose: true,
                                            message: response.data.msg,
                                            type: 'error'
                                       });
                                        return
                                   }
                               }).catch((error) => {
                                    this.$message({
                                        showClose: true,
                                        message: error,
                                        type: 'error'
                                   });
                                    return
                               });
                           }
                       });

                   }
               } else {
                    this.$message({
                        showClose: true,
                        message: '作废前，请先保存单据，单号不能为空！',
                        type: 'error'
                   });
               }

           },
            //下一张
            nextBill() {
                if (this.formData.cash_type_id == '' || this.formData.cash_type_id == null) {
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : 1,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               } else {
                    let i = this.$XEUtils.add(Number(this.formData.cash_type_id), 1);
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : i,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               }
           },
            //上一张
            preBill() {
                if (this.formData.cash_type_id == '' || this.formData.cash_type_id == null) {
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : 1,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               } else {
                    let i = this.$XEUtils.subtract(Number(this.formData.cash_type_id), 1);
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : i,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               }
           },
            //付款单位
            companyNameChangeEvent(item, nameType) {
                //console.log(item,nameType)
                this.formData.company_id = item.id;
                if (nameType == 'customer_name') {
                    this.formData.company_name = item.customer_name;
               } else if (nameType == 'supplier_name') {
                    this.formData.company_name = item.supplier_name;
               }
                this.$_searchWarehouseBillByKdOrJc(this.formData.company_name).then(() => {
                    /*                        this.getPaidUp();
                        this.getTotalAmount();
                    this.getAllocatedAmount();
                    this.getDispensAbility();*/
                    this.radioEvent(this.formData.cash_type);
               })
           },

            _searchWarehouseBillByKdOrJcEvent() {
                if (this.formData.allocated_amount != 0) {
                    this.$XModal.confirm('保存后，将清空“结算金额”？').then(type => {
                        //const $grid = this.$refs.xGrid
                        if (type === 'confirm') {
                            this.$_searchWarehouseBillByKdOrJc(this.formData.company_name);
                       }
                   })
               } else {
                    this.$_searchWarehouseBillByKdOrJc(this.formData.company_name);
               }

           },
            _createRowFooterDataByReceipt(list, cashType) {
                if (list == null) {
                    list = [];
               }
                for (var index = list.length; index < 30; index++) {
                    list.push({
                        id: '',
                        company_name_assist : '',
                        company_uuid_assist : '',
                        subject_id_cash_data: '',
                        subject_name_cash_data : '',
                        money_cash_data : '',
                        remark: '',
                        cash_id: '',//对应出纳单据编号
                        cash_type : cashType,//
                        amount: '',//结算金额
                        last_date_cash_data : '',//最后修改日期
                        last_name_cash_data : '',//最后修改人
                        registrar_cash_data : '',//记录人
                        check_number : '',//支票号码
                        draw_company : '',//出票单位
                        order_id : index + 1,
                   })
               }
                return list;
           },
            //单击
            cellClickEventMain({row, column}) {
                if (column.property == 'check_number') {
                    const $table = this.$refs.CashDataxGrid
                    // 重写默认的覆盖式，改为追加式
                    $table.setActiveCell(row, column)
               }
                this.rowMain = row;
           },

            //一般费用单，单击单元，把改行的值赋予到对应的row中
            cellClickEventModal({row, column}) {
                if (column.property == 'kick_back_total_balance' || column.property == 'pounds_fees_balance' || column.property == 'wind_cut_fees_balance' ||
                    column.property == 'loading_car_fees_balance' || column.property == 'transfer_fees_balance' || column.property == 'freight_fees_balance'
                    || column.property == 'taxes_fees_balance') {
                    let title = column.title.substring(0, column.title.indexOf("("));
                    //this.rowMain.cash_data_remark = column.title
                    this.rowMain.fees_name = title;
                    this.rowMain.money_cash_data = row[column.property];
                    this.rowMain.bill_id_kd_or_jc = row.b_id;
                    this.rowMain.company_name = row.company_name;
                    this.rowMain.sales_man = row.sales_man;
                    this.rowMain.company_uuid = row.company_uuid;
               }
           },
            //单击
            cellClickEventFooter({row, column}) {
                if (column.property == 'check_number' || column.property == 'check_type') {
                    const $table = this.$refs.xGridFooter
                    // 重写默认的覆盖式，改为追加式
                    $table.setActiveCell(row, column)
               }
           },
            //双击勾单
            cellClickEvent({row, column}) {
                if (column.property == 'isOk') {
                    //判断可分配金额是否大于0，
                    //如果可分配金额大于等于余额，结算金额 = 金额   结算金额 + 余额
                    //如果可分配金额小于余额，结算金额 = 结算金额 + 可分配金额
                    //this.$XModal.alert({content: row.cash_type, maskClosable: true})
                    if (row.cash_type == 'SKD' || row.cash_type == 'FKD') {
                        if (row.balance < 0) {
                            //余额负数，说明挂账，请到挂账单号上操作。
                            this.$XModal.alert({content: '余额负数，说明挂账，请到挂账单号上操作！', maskClosable: true})
                       } else if (this.formData.dispens_ability > 0) {
                            this.setAmount(row);
                       } else {
                            this.$XModal.alert({content: '可分配余额不足！', maskClosable: true})
                       }
                   } else {
                        this.setAmount(row);
                   }

                    //console.log(row, column)
               }
           },
            setAmount(row) {
                //amount 结算金额
                //balance 余额
                //dispensAbility 可分配金额

                //可分配金额 大于 未结余额
                if (this.formData.dispens_ability >= row.balance) {
                    //结算金额 = （已输入）结算金额 + 未结余额
                    row.amount = this.$XEUtils.add(row.amount, row.balance);
                    //row.balance = 0;
               } else if (this.formData.dispens_ability > 0) {
                    //可分配金额 大于0，
                    //结算金额 = （已输入）结算金额 + 可分配金额
                    //row.balance = this.$XEUtils.subtract(row.balance, this.getDispensAbility);
                    row.amount = this.$XEUtils.add(row.amount, this.formData.dispens_ability);
               }
                this.getPaidUp();
                this.getTotalAmount();
                this.getAllocatedAmount();
                this.getDispensAbility();
                //row.amount = Number(row.amount);
                this.setBalance(row);//设置 确认状态，判断是否需要打勾
           },
            //设置 确认状态，判断是否需要打勾
            setBalance(row) {
                if (this.formData.cash_type == 'SKD' || this.formData.cash_type == 'FKD') {
                    if (this.isNumberZeroVue(row.amount)) {
                        //row.balance = Number(0);
                        //console.log("row.balance",row.balance)
                        //要判断一下，这个 已支付 是否包含了 结算金额，如果是的话，则只减 已支付 即可
                        row.balance = this.$XEUtils.subtract(this.$XEUtils.subtract(row.total_amount, row.amount), row.amount_other);

                        if (row.balance == 0) {
                            row.isOk = '✓'
                       } else {
                            row.isOk = ''
                       }
                   }
                    row.payend = this.$XEUtils.add(row.amount, row.amount_other);
               }
           },

            pageChangeEvent({currentPage, pageSize}) {
                this.dropDownTablePage.currentPage = currentPage
                this.dropDownTablePage.pageSize = pageSize
                this.$_searchSubjectList();
                // const activeRow = this.$refs.baseGird.getActiveRecord().row;
                //this.searchJCWarehouse(activeRow.specification);
           },
            /**
             * 选中科目编码下拉，用于event {row}转row
             * @param row
             */
            radioChangeEvent({row}, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow) {
                this.radioChangeEnterEvent(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow)
           },
            radioChangeEnterEvent(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow) {
                if (row != null) {
                    this.$_isHasFatherId(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow)
                    //row.id 查询判断是否有子类，如果有的话，继续查询
               }
           },
            radioSummaryChangeEvent({row}) {
                this.radioSummaryChangeEnterEvent(row);
           },
            radioSummaryChangeEnterEvent(row) {
                if (row != null) {
                    const activeRow = this.$refs.CashDataxGrid.getActiveRecord().row;
                    activeRow.summary_cash_data = row.summary_name;
               }
                this.$refs['xDownSummary'].hidePanel();
                this.xDownSummaryIsShow = false
           },
            /**
             * 选中摘要下拉 CashDataxGrid
             * @param row
             */
            pageChangeSummaryEvent({currentPage, pageSize}) {
                this.dropDownTablePageSummary.currentPage = currentPage
                this.dropDownTablePageSummary.pageSize = pageSize
                const activeRow = this.$refs.CashDataxGrid.getActiveRecord().row;
                this.$_searchSummaryList(activeRow.summary_cash_data);
           },

            editMethod({row, column}) {
                const $table = this.$refs.CashDataxGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            editMethodFooter({row, column}) {
                const $table = this.$refs.xGridFooter
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },

            //（下拉主数据 用于设置上下选择行的位置）、事件、弹框的GridRef、赋值GridRef、下拉框是否显示
            keyUpDropDownEvent(dropDownTableData, event, dropDownGridRef, mainGridRef, vxePulldown, xDownSubjectIdIsShow) {
                //console.log(xDown);
                //console.log('this.$refs[xDown].isPanelVisible()', this.$refs[xDown].isPanelVisible());
                ////console.log(dropDownGrid,this.$refs[dropDownGrid],dropDownTableData)
                ////console.log(event.$event.code)
                let i = 0;
                if (event.$event.code == 'Enter' || event.$event.code == 'NumpadEnter') {
                    //this.$refs[dropDownGrid].getRadioRow();
                    i = this.$refs[dropDownGridRef].getRowIndex(this.$refs[dropDownGridRef].getRadioRecord());
                    //摘要
                    if (dropDownGridRef == 'dropDownGridSummary') {
                        this.radioSummaryChangeEnterEvent(this.$refs[dropDownGridRef].getRadioRecord());
                        this.$refs['xDownSummary'].hidePanel();
                        this.xDownSummaryIsShow = false;
                   }
                    if (dropDownGridRef == 'dropDownGrid' || dropDownGridRef == 'dropDownGridFooter') {
                        //回车才出发是否继续查询有没有子类
                        this.radioChangeEnterEvent(this.$refs[dropDownGridRef].getRadioRecord(), true, dropDownGridRef, mainGridRef, vxePulldown, xDownSubjectIdIsShow);
                   }

                    //console.log('Enter', i);
               } else if (event.$event.code == 'ArrowUp' || event.$event.code == 'ArrowDown') {
                    //console.log("执行上下箭头", event.$event.code);
                    if (this.$refs[dropDownGridRef].getRadioRecord() != null) {
                        i = this.$refs[dropDownGridRef].getRowIndex(this.$refs[dropDownGridRef].getRadioRecord());
                   }
                    if (event.$event.code == 'ArrowUp') {
                        if (i > 0) {
                            i = i - 1;
                       }
                   } else if (event.$event.code == 'ArrowDown') {
                        ////console.log('dropDownTableData.length',dropDownTableData.length)
                        if (this.$refs[dropDownGridRef].getRadioRecord() == null) {
                            i = 0;
                       } else {
                            i = i + 1;
                       }
                   }
                    ////console.log("dropDownTableData: " + dropDownTableData)
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[i]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[i]);
               }
                ////console.log('this.$refs[dropDownGrid]', this.$refs[dropDownGrid])
                if (i != 0) {
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[i]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[i]);
               } else {
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[0]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[0]);
               }

           },
            //如果为空，则自动添加：当天日期
            cashDateBlur() {
                if (this.isBlankVue(this.formData.cash_date)) {
                    this.formData.cash_date = new Date();
               }
           },

            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            _reMakeFooterData(footerData, cashType) {
                for (var t in footerData) {
                    if (!this.isNumberVue(footerData[t].amount)) {
                        footerData[t].amount = 0;
                        footerData[t].isOk = '';
                        footerData[t].cash_type = cashType;
                   }
               }
                return footerData;
           },
            consoleEvent() {
                console.log('test hide-panel')
           },
            //查询收款单
            async searchReceipt() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                await this.$axios({
                    method: 'post',
                    url: '/admin/receipt/searchReceipt',
                    params: {
                        id: this.formData.id,
                        cash_type_id : this.formData.cash_type_id,
                        type: this.formData.type,
                        year: this.formData.year,
                        month: this.formData.month
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    ////console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.formData;
                        this.footerData = [];
                        this.changeRadioEvent();
                        this.tableDataMain = this.$_createRowDataByReceipt(response.data.tableDataMain);
                        this.$_searchWarehouseBillByKdOrJc(this.formData.company_name).then(() => {
                            if (this.formData.cash_type == 'ZJRZ' || this.formData.cash_type == 'QTSRD' || this.formData.cash_type == 'YBFYD') {
                                this.footerData = this._createRowFooterDataByReceipt(response.data.footerData, this.formData.cash_type);
                                //this.$XModal.alert(this.footerData)
                                //console.log('this.footerData',this.footerData)
                           }
                       });
                        //console.log('v')

                        if (this.formData.cash_type == 'SKD' || this.formData.cash_type == 'FKD') {
                            this.footerData = this._reMakeFooterData(response.data.footerData, this.formData.cash_type);
                       }
                   } else {
                        //this.$XModal.alert(response.data.msg)
                        this.disableGrid = true;
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                   }

               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
                //return Promise.resolve();
           },
            clearSujectEvent(row) {
                row.subject_id = '';//科目编码
                row.subject_name = '';//科目名称
                //row.subject_id_and_name = '';//科目编码&科目名称
                row.company_name_assist = '';//辅助项
                row.company_uuid_assist = '';//辅助项
           },
            //切换底部列字段
            changeRadioEvent() {
                //this.$XModal.message({ content: this.formData.cash_type, status: 'success'})
                if (this.formData.cash_type == 'YSCYS') {
                    this.footerTableColumn = this.footerTableColumnYSCYS;
               } else if (this.formData.cash_type == 'ZJRZ' || this.formData.cash_type == 'QTSRD' || this.formData.cash_type == 'YBFYD') {
                    this.footerTableColumn = this.footerTableColumnZJRZorQTSRDorYBFYD;
               } else if (this.formData.cash_type == 'SDJ' || this.formData.cash_type == 'YFK') {
                    this.footerTableColumn = this.footerTableColumnSDJ;
               }
                if (this.formData.cash_type == 'YBFYD') {
                    this.tableColumn = this.tableColumnYBFYD;
               }
           },
            //选择不同的类型
            radioEvent(e) {
                if (this.formData.allocated_amount != 0) {
                    this.$XModal.confirm('保存后，将清空“结算金额”？').then(type => {
                        //const $grid = this.$refs.xGrid
                        if (type === 'confirm') {
                            this.radioEventDoAction(e);
                       }
                   })
               } else if (!this.isBlankVue(this.formData.id)) {
                    this.$XModal.confirm('切换其他类型，保存后，将清空“结算金额”？').then(type => {
                        //const $grid = this.$refs.xGrid
                        if (type === 'confirm') {
                            this.radioEventDoAction(e);
                       }
                   })
               } else {
                    this.footerData = [];
                    this.radioEventDoAction(e);
               }
           },
            onSubmit() {
                let loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.isDataChanage = false;
                let url = '/admin/receipt/saveOrUpdateReceipt';
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        formData: this.formData,
                        tableDataMain: this.tableDataMain,
                        footerData: this.footerData
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.footerData = response.data.footerData;
                        this.formData = response.data.formData;
                        this.tableDataMain = response.data.tableDataMain;
                        //this.formData = response.data.warehouseBill;
                        //this.tableDataMain = this.$_createRowDataByReceipt(response.data.stocks);
                        this.$router.push({
                            path: "/receipt",
                            query: {
                                cash_type_id : this.formData.cash_type_id,
                                type: this.formData.type,
                                year: this.formData.year,
                                month: this.formData.month
                           }
                       })
                        this.searchReceipt();
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
               });

           },
            //自动分配
            automatic() {
                //重新进行分配
                for (var c in this.footerData) {
                    this.setAmount(this.footerData[c]);
               }
           },
            //合计冲账金额
            getTotalBalanceMoney() {
                let totalBalanceMoney = Number(0);
                for (var t in this.tableDataMain) {
                    if (this.isNumberVue(this.tableDataMain[t].money_cash_data)) {
                        totalBalanceMoney = this.$XEUtils.add(totalBalanceMoney, Number(this.tableDataMain[t].balance_money));
                   }
               }
                this.formData.total_balance_money = Number(totalBalanceMoney) ? Number(totalBalanceMoney) : 0;
           },
            //合计实收
            getPaidUp() {
                //console.log('getTotalAmount 合计金额');
                let paidUp = Number(0);
                for (var t in this.tableDataMain) {
                    if (this.isNumberVue(this.tableDataMain[t].money_cash_data)) {
                        paidUp = this.$XEUtils.add(paidUp, Number(this.tableDataMain[t].money_cash_data));
                        paidUp = this.$XEUtils.subtract(paidUp, Number(this.tableDataMain[t].balance_money));
                   }
               }
                /*for (var f in this.footerData) {
                    //如果底部不为空，说明合计的时候需要合计起来，用来计算预付、已收，付了多少钱
                    if (this.isNumberVue(this.footerData[f].amount) && (this.footerData[f].cash_type == 'SDJ' || this.footerData[f].cash_type == 'YFK')) {
                        if (Number(this.footerData[f].amount) > 0) {
                            paidUp = this.$XEUtils.subtract(paidUp, Number(this.footerData[f].amount));
                       } else {
                            paidUp = this.$XEUtils.add(paidUp, this.$XEUtils.subtract(0, Number(this.footerData[f].amount)));
                       }
                   }
               }*/
                //遍历底部，判断是否FKD，是的话则判断金额是否存在，存在的话合计进去，
                this.formData.paid_up = Number(paidUp) ? Number(paidUp) : 0;
                this.formData.balance = Number(paidUp) ? Number(paidUp) : 0;
           },
//合计  金额+冲账金额 + 结算金额 = 合计金额
            getTotalAmount() {
                let totalAmount = Number(0);
                for (var t in this.tableDataMain) {
                    if (this.isNumberVue(this.tableDataMain[t].money_cash_data)) {
                        totalAmount = this.$XEUtils.add(totalAmount, Number(this.tableDataMain[t].money_cash_data));
                   }
               }
                this.formData.total_amount = Number(totalAmount) ? Number(totalAmount) : 0;
           },
            //已分配金额
            getAllocatedAmount() {
                //console.log('getAllocatedAmount', this.footerData);
                let allocatedAmount = Number(0);
                for (var t in this.footerData) {
                    //console.log('getAllocatedAmount',this.footerData[t].amount)
                    //console.log(this.footerData[t]);
                    //&& (this.footerData[t].cash_type == 'SKD' || this.formData.cash_type == 'ZJRZ' || this.formData.cash_type == 'QTSRD' || this.formData.cash_type == 'YBFYD' || this.formData.cash_type == 'FKD')
                    if (this.isNumberVue(this.footerData[t].amount)) {
                        allocatedAmount = this.$XEUtils.add(allocatedAmount, Number(this.footerData[t].amount));
                   }
               }
                this.formData.allocated_amount = Number(allocatedAmount) ? this.$XEUtils.round(Number(allocatedAmount), 2) : 0;
           },
            //可分配金额(数据库不需要这个字段，该字段等于未结算金额)
            getDispensAbility() {
                //return this.getAllocatedAmount;
                this.formData.dispens_ability = this.$XEUtils.subtract(Number(this.formData.paid_up), Number(this.formData.allocated_amount));
           },
            //计算挂数金额
            getHangMoney() {
                let money = Number(0);
                for (var t in this.footerData) {
                    //console.log(this.footerData[t].amount)
                    //console.log(this.footerData[t]);
                    if ((this.footerData[t].cash_type == 'SKD' || this.footerData[t].cash_type == 'FKD') && this.isNumberVue(this.footerData[t].amount) && this.isNumberVue(this.footerData[t].total_amount) && Number(this.footerData[t].total_amount) < Number(this.footerData[t].amount)) {
                        money = this.$XEUtils.add(money, this.$XEUtils.subtract(Number(this.footerData[t].amount), Number(this.footerData[t].total_amount)));
                   }
               }
                this.formData.hang_money = Number(money) ? this.$XEUtils.round(Number(money), 2) : 0;
           },
            radioEventDoAction(e) {
                this.formData.cash_type = e;
                this.changeRadioEvent();
                if (this.formData.cash_type == 'ZJRZ' || this.formData.cash_type == 'QTSRD' || this.formData.cash_type == 'YBFYD') {
                    if (this.footerData.length == 0) {
                        this.footerData = this._createRowFooterDataByReceipt(this.footerData, this.formData.cash_type);
                   }
               } else {
                    this.$_searchWarehouseBillByKdOrJc(this.formData.company_name).then(() => {
                        this.getPaidUp();
                        this.getTotalAmount();
                        this.getAllocatedAmount();
                        this.getDispensAbility();
                   })
                    if (this.formData.cash_type == 'SKD' || this.formData.cash_type == 'FKD') {
                        this.footerData = this._reMakeFooterData(this.footerData, this.formData.cash_type);
                   }
               }
           }
       }, computed: {
            isShowYSCYS: function () {
                if (this.formData.type == 'YSCYS') {
                    return true
               } else {
                    return false
               }
           },
            isShowYSCYF: function () {
                if (this.formData.type == 'YSCYF') {
                    return true
               } else {
                    return false
               }
           },


            getTitle: function () {
                if (this.formData.cash_type == 'YSCYS') {
                    return '应收冲应收'
               } else if (this.formData.cash_type == 'YSCYF') {
                    return '应付冲应付'
               } else {
                    return '未知'
               }
           },


       },
        created() {
            this.loading = true
            this.$_searchCustomerList('');
            this.$_searchSupplierList('');
            this.$_searchSummaryList('');//查询摘要
            this.$_searchAccountList('');//经手人 全部用户
            var list = [];
            this.tableDataMain = this.$_createRowDataByReceipt(list);

            /**
             *
             * 判断是否打开已有单据
             *
             * @type {string | (string | null)[]}
             */

            this.formData.cash_type_id = this.$route.query.cash_type_id;
            this.formData.id = this.$route.query.id
            this.formData.type = this.$route.query.type
            this.formData.year = this.$route.query.year
            this.formData.month = this.$route.query.month

            if (!this.isBlankVue(this.$route.query.type)) {
                this.radioEvent(this.$route.query.type)
           }

            if (this.isBlankVue(this.$route.query.cash_type_id) && this.isBlankVue(this.$route.query.id)) {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name;
                //如果是付款单，设置默认选择付款单类型中的付款单
                if (this.formData.type == 'YSCYS') {
                    this.formData.type = 'YSCYS';
                    this.formData.cash_type = 'YSCYS';
               } else if (this.formData.type == 'QTSRD') {
                    this.formData.type = 'QTSRD'
                    this.formData.cash_type = 'QTSRD'
               } else if (this.formData.type == 'YBFYD') {
                    this.formData.type = 'YBFYD'
                    this.formData.cash_type = 'YBFYD'
               } else {
                    this.formData.type = 'SKD'
                    this.formData.cash_type = 'SKD'
               }
                /*if(this.isBlankVue(this.formData.year_month)) {
                    this.formData.year_month = this.formData.cash_date

               }*/
                this.changeYearMonthEvent();
           } else {
                this.openCashTypeId = this.formData.cash_type_id;//用于上一页，下一页
                this.searchReceipt().then(() => {
                    setTimeout(() => {
                        this.getPaidUp();
                        this.getTotalAmount();
                        this.getAllocatedAmount();
                        this.getDispensAbility();
                        //this.formData.year_month = this.formData.cash_date;
                        this.changeYearMonthEvent();
                   }, 1000)

               });
           }
            this.changeRadioEvent();
            //this.$_getAccountListByRoleId('16');//经手人 销售

       }
   };


</script>

<style scoped>

    /deep/ table .el-input--mini .el-input__inner, table .el-input-number--mini {
        height: 28px;
        line-height: 28px;
   }

    /deep/ .el-input-group__prepend {
        border: initial;
   }

    /deep/ #openCashTypeId > div > .el-input-group__prepend {
        padding: 0 0px
   }
</style>
